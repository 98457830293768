@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.highlights {
  display: flex;
  flex-direction: column;
  max-width: 100vw;

  .active {
    border-bottom: 3px solid $primary-green;
    padding-bottom: 3px;
    font-weight: 600;

    @include breakpoint-lg-up {
      border-bottom: 7px solid $primary-green;
    }
  }

  .seeMore {
    align-items: center;
    border-radius: 8px;
    border: 1px solid $primary-graphite;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 181px;
    text-align: center;
    width: 134px;

    .seeMoreText {
      font-weight: 600;
    }
  }

  .explorerBtn {
    border-radius: 100px;
    margin: 12px auto;
  }

  .marginLeft {
    margin-left: 16px;
  }

  .wrapper {
    display: flex;
    margin-top: 20px;
    position: relative;

    .arrow {
      display: none;

      @include breakpoint-lg-up {
        display: initial;
      }

      background: transparent;
      box-shadow: inset 0 0px 20px 20px #fff;
      border: 0;
      cursor: pointer;
      font-size: 32px;
      position: absolute;
      top: 25%;
      z-index: 10;

      &.right {
        right: -60px;
      }

      &.left {
        left: -60px;
      }
    }

    &[data-left-arrow="true"] {
      .left {
        @include fadeIn;
      }
    }
    &[data-left-arrow="false"] {
      .left {
        @include fadeOut;
      }
    }
    &[data-right-arrow="true"] {
      .right {
        @include fadeIn;
      }
    }
    &[data-right-arrow="false"] {
      .right {
        @include fadeOut;
      }
    }
  }

  .scroll {
    display: flex;
    gap: 20px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    div.roomPod {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    div.roomPod:first-of-type {
      margin-left: 16px;
    }
    div.roomPod:last-of-type {
      margin-right: 16px;
    }

    // @include breakpoint-sm-up {
    //   div.roomPod:first-of-type {
    //     margin-left: 16px;
    //   }
    //   div.roomPod:last-of-type {
    //     margin-right: 16px;
    //   }
    // }
  }
}
