@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.bannerWapper {
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
  height: 50vh;
  width: 100%;

  .banner {
    transform: scale(1.1);
    z-index: 0;
    object-position: 100% 20%;

    @include breakpoint-sm-up {
      transform: scale(1);
      object-position: 100% 60%;
    }
  }
}

.maxWidth {
  margin: 0 auto;
  max-width: 1136px;
  width: 100%;
}

.infoSection {
  align-items: center;
  display: flex;
  gap: 46px;
  margin-left: 46px;
}

.searchForm {
  display: none;

  @include breakpoint-md-up {
    @include defaultShadow;
    align-items: center;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    max-width: 328px;
    padding: 20px;
    z-index: 1;
  }
}

.bannerBg {
  background-color: rgba(234, 236, 228, 0.7);
  padding: 24px;
  position: absolute;
  top: 18%;
  left: 65px;
  height: 60px;
  white-space: nowrap;

  @include breakpoint-md-up {
    display: none;
    // position: relative;
    // top: initial;
    // left: initial;
  }

  .underlineText {
    font-weight: 700;
    margin-top: 5px;
    text-decoration: underline;
    text-decoration-color: $primary-green;
    text-decoration-thickness: 5px;
  }
}
