@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_breakpoints.scss";


.new {
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;

  .text {
    background-color: orange;
    color: white;
    padding: 3px 10px;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 500;
    margin-right: 16px;
    position: absolute;
    right: 0;
    top: 0;

    @include breakpoint-lg-up {
      margin-right: 3px;
    }
  }
}

.tab {
  align-items: center;
  display: flex;
  padding-left: 30px;
  justify-content: center;
  height: 55px;

  @include breakpoint-lg-up {
    gap: 50px;
  }

  .link {
    font-weight: 400;
  }

  .active {
    color: $primary-green;
    font-weight: 700;

    @include breakpoint-lg-up {
      border-bottom: 7px solid $primary-green;
    }
  }
}