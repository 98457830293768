@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.advantagesRent {
  margin-top: 16px;

  ul {
    list-style-type: none;
    margin: 16px 0 0 0;
    padding: 0;
    position: relative;
    @include breakpoint-md-up {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      margin-bottom: 20px;
      @include breakpoint-md-up {
        width: 450px;
      }
    }
  }

  .title {
    button {
      text-align: left;
      padding: 0;
    }

    .icon {
      color: $primary-green;
      min-width: 24px;
      min-height: 24px;
    }
  }

  .description {
    margin-left: 36px;
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }
}
