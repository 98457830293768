@import "~@lc/ui/styles/settings/_breakpoints.scss";

.section {
  padding: 0 16px;
}

.maxWidthAndAlignCenter {
  @include breakpoint-md-up {
    margin: 0 auto;
    max-width: 1136px;
  }
}

.searchForm {
  display: flex;
  justify-content: center;

  @include breakpoint-md-up {
    display: none;
  }
}

.tabs {
  margin: 0px 0px 16px 0px;
  @include breakpoint-md-up {
    margin: 15px 0px;
  }
}

.howLcWorks {
  display: none;

  @include breakpoint-md-up {
    display: block;
  }
}
