@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.room {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  color: $primary-green;
  font-weight: 600;
}

.imageWrapper {
  border-radius: 8px;
  display: flex;
  position: relative;
  height: 180px;
  width: 250px;

  .price {
    background-color: $primary-graphite;
    bottom: 0;
    border-bottom-left-radius: 8px;
    color: #ffffff;
    padding: 4px 12px;
    position: absolute;
    margin: 0;

    span {
      font-weight: 700;
    }
  }
}

.imageCarousel {
  align-items: center;
  display: flex;
  overflow: hidden;

  button {
    align-items: center;
    background-color: $primary-graphite;
    border: 0;
    border-radius: 50%;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    padding: 5px;
    position: absolute;
    z-index: 10;

    &:hover {
      opacity: 0.9;
    }

    &.right {
      right: 10px;
    }

    &.left {
      left: 10px;
    }
  }
}

.image {
  border-radius: 8px;
  height: 250px;
  width: 250px;

  &[data-active="true"] {
    @include fadeIn;
  }
  &[data-active="false"] {
    @include fadeOut;
  }
}

.address {
  align-items: center;
  display: flex;
  font-weight: 600;
  margin-top: auto;
  gap: 0;
  height: 32px;
  max-height: 32px;

  svg {
    min-height: 26px;
    min-width: 26px;
  }
}
