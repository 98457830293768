@import "~@lc/ui/styles/settings/colors";

.divisor {
  border: 0;
  border-top: 1px solid $secondary-gray-light;
  width: 100%;

  &.noBottomMargin {
    margin-bottom: 0;
  }

  &.noTopMargin {
    margin-top: 0;
  }
}
