@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.expertiseList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  list-style-type: none;
  margin: 16px 0;
  padding: 0;
  position: relative;
  max-width: 552px;

  &:before {
    background-image: linear-gradient($primary-green 0%, #ffffff 110%);
    border-radius: 50px;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 5px;
  }

  li {
    border-bottom: 1px dashed $primary-green;
    padding: 12px 20px 12px;
  }

  @include breakpoint-md-up {
    align-items: center;
    flex-direction: row;
    max-width: initial;

    &:before {
      content: none;
    }

    li {
      border-bottom: 0;
      border-right: 1px dashed $secondary-gray-dark;
      padding: 0 10px;
    }
    li:first-child {
      padding-left: 0;
    }
    li:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }

  .expertiseText {
    align-items: center;
    display: flex;
    font-weight: 600;
    gap: 10px;

    &.modal {
      font-weight: 400;
    }

    svg {
      height: 26px;
      width: 32px;
    }

    @include breakpoint-md-up {
      svg {
        height: 31px;
        width: 35px;
      }
    }
  }
}
