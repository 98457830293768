@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_breakpoints.scss";

.simpleToRent {
  background-color: $secondary-gray-very-light;
  padding-top: 16px;
  padding-left: 16px;

  @include breakpoint-md-up {
    background-color: #ffffff;
  }
  ol {
    list-style-type: none;
    margin: 16px 0 0 0;
    padding: 0;
    position: relative;

    @include breakpoint-md-up {
      display: flex;
      justify-content: space-between;
    }

    li {
      align-items: center;
      display: flex;
      gap: 20px;
      padding-bottom: 20px;

      @include breakpoint-md-up {
        flex-direction: column;
      }

      &:first-child {
        &::before {
          background: #a6a6a6;
          content: "";
          top: 0%;
          left: 20px;
          width: 1px;
          height: 90%;
          position: absolute;

          @include breakpoint-md-up {
            top: 21%;
            left: 65px;
            width: 86%;
            height: 1px;
          }
        }
      }
    }
  }

  .numberItem {
    align-items: center;
    background: linear-gradient(135deg, rgb(52, 186, 146) 0%, white 110%);
    border-radius: 50%;
    box-shadow: 3px 4px 8px 0px rgb(127 127 127 / 25%);
    display: flex;
    justify-content: center;
    height: 42px;
    position: relative;
    min-width: 42px;
    width: 42px;

    @include breakpoint-md-up {
      height: 80px;
      width: 80px;
    }

    span {
      align-items: center;
      background: #e6e6e6;
      border-radius: 50%;
      display: flex;
      font-weight: 600;
      justify-content: center;
      height: 30px;
      width: 30px;

      @include breakpoint-md-up {
        height: 60px;
        width: 60px;
      }
    }
  }

  .text {
    @include breakpoint-md-up {
      text-align: center;
      max-width: 150px;
    }
  }
}
